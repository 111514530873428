<template>
  <b-link class="brand-logo">
    <logo
      :width="150"
      :height="150"
    />
    <h2
      v-if="appName === 'Resident'"
      class="brand-text text-primary ml-1"
    >
      {{ appName }}
    </h2>
  </b-link>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue';
import { $themeConfig } from '@themeConfig';
import Logo from '@/layouts/components/Logo.vue';

export default {
  components: {
    BLink,
    Logo,
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      appName,
      appLogoImage,
    };
  },

};
</script>
